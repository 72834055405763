import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ModalController } from '@ionic/angular'
import { ThanksForBuyingComponent } from 'src/app/components/thanks-for-buying/thanks-for-buying.component'
import { AlertService } from 'src/app/services/alert.service'
import { SqlGenericService } from 'src/app/services/sqlGenericService'
import { HelpComponent } from '../help/help.component'

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit {
  public currentView = 'subscriptions'
  public history = []
  public hasSubscription = false
  public subscription: {
    plan: string
    class: string
    price: number
    start_date: string | Date
    end_date: string | Date
  } = null

  public plans = {
    light: {
      name: 'Light',
      price: 199,
      features: ['$199 MXN', 'Válido por 7 días a partir de la compra'],
    },
    gold: {
      name: 'Gold',
      price: 249,
      features: ['$249 MXN', 'Válido por 10 días a partir de la compra'],
    },
    platinum: {
      name: 'Platinum',
      price: 349,
      features: ['$349 MXN', 'Válido por 14 días a partir de la compra'],
    },
  }

  constructor(
    private location: Location,
    private modalCtrl: ModalController,
    private router: Router,
    private sqlGenericService: SqlGenericService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.checkSubscription()
    this.getHistory()
  }

  toggleView() {
    this.currentView = this.hasSubscription ? 'membership' : 'subscriptions'
  }

  goBack() {
    this.location.back()
  }
  async openThanksForBuying() {
    const modalConfig = {
      component: ThanksForBuyingComponent,
      backdropDismiss: false,
    }

    const modal = await this.modalCtrl.create(modalConfig)
    modal.present()
  }

  async checkSubscription() {
    const userId = localStorage.getItem('id_Usuario')

    const subscriptionQuery = `
      SELECT * FROM subscriptions
      WHERE id_user = ${userId} AND
      status = 1
    `

    const [sub] = (
      (await this.sqlGenericService
        .excecuteQueryStringReference(subscriptionQuery, 'checkSubscription')
        .toPromise()) as any
    ).parameters

    if (!sub) {
      this.currentView = 'subscriptions'
      return (this.hasSubscription = false)
    }
    this.currentView = 'membership'
    const startDate = new Date(`${sub.start_date}`)
    let endDate = new Date(`${sub.start_date}`)
    endDate = new Date(
      endDate.setDate(endDate.getDate() + Number(sub.validity))
    )
    const today = new Date()

    if (today > endDate) {
      this.hasSubscription = false

      const updateQuery = `
        UPDATE subscriptions
        SET status = 0
        WHERE id = ${sub.id} 
      `

      await this.sqlGenericService
        .excecuteQueryStringReference(updateQuery, 'updateSubscription')
        .toPromise()
    } else {
      this.hasSubscription = true
      this.subscription = {
        plan: sub.plan,
        class: sub.plan.toLowerCase(),
        price: this.plans[sub.plan.toLowerCase()].price,
        start_date: startDate,
        end_date: endDate,
      }
    }
  }

  getHistory() {
    const userId = localStorage.getItem('id_Usuario')

    const query = `SELECT * FROM subscriptions
    WHERE id_user = ${userId}
    ORDER BY start_date DESC`

    this.sqlGenericService
      .excecuteQueryStringReference(query, 'getHistory')
      .subscribe((res) => {
        const subs = (res as any).parameters as any[]

        this.history = subs.map((s) => ({
          id: s.id,
          plan: s.plan,
          date: s.start_date,
        }))
      })
  }

  goPayment(plan) {
    if (!this.hasSubscription) {
      this.router.navigate(['/payments', plan])
    } else {
      this.alertService.errorAlert(
        'Ya tienes un paquete activo',
        'No puedes comprar otro paquete hasta que el que tienes actualmente expire'
      )
    }
  }

  async openHelpModal() {
    const modalConfig = {
      component: HelpComponent,
      backdropDismiss: false,
    }

    const modal = await this.modalCtrl.create(modalConfig)
    modal.present()
  }
}
