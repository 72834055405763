import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { HttpErrorResponse } from '@angular/common/http'
import { SqlGenericService } from 'src/app/services/sqlGenericService'
import { AlertService } from './../../services/alert.service'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { Capacitor } from '@capacitor/core'
import { GooglePlus } from '@ionic-native/google-plus/ngx'
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx'
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in'
import { NavController } from '@ionic/angular'

@Component({
  selector: 'app-login-main',
  templateUrl: './login-main.component.html',
  styleUrls: ['./login-main.component.scss'],
})
export class LoginMainComponent implements OnInit {
  public url: any
  public id: any = null
  public visto: boolean = true
  public platform: boolean
  public web:boolean
  public registrado: boolean = false
  public guardar: boolean = false
  public data: any = {
    email: {
      error: false,
      value: '',
    },
    pass: {
      error: false,
      value: '',
    },
    repass: {
      error: false,
      value: '',
    },
  }
  public loginRender: any = {
    inputEmail: {
      required: true,
      placeholder: 'Email.....',
      value: null,
      error: false,
      type: 'text',
      length: 100,
    },
    inputContrasena: {
      required: true,
      placeholder: 'Contraseña.....',
      value: null,
      error: false,
      type: 'text',
      length: 100,
    },
  }

  constructor(
    private router: Router,
    private sqlGenericService: SqlGenericService,
    private alertService: AlertService,
    private afAuth: AngularFireAuth,
    private googlePlus: GooglePlus,
    private fb: Facebook,
    public navCtrl: NavController
  ) {}

  ngOnInit() {
    this.Servicio()
    if (Capacitor.getPlatform() === 'ios') {
      this.platform = true
    }
    if (Capacitor.getPlatform() === 'web') {
      this.web = true
      console.log("si es Web",this.web)
    }
    const register = localStorage.getItem('registrado')
   // console.log(register)
    if (register == 'true') {
      this.registrado = true
    } else {
      this.registrado = false
    }
  }

  goRegistro() {
    localStorage.setItem('registrado', 'false')
    localStorage.removeItem('userSession')
    localStorage.removeItem('codigoSms')
    localStorage.removeItem('celular')
    localStorage.removeItem('id_password')
    localStorage.removeItem('id_Usuario')
    localStorage.removeItem('codigoSmsUpdate')
    // this.router.navigateByUrl('/Registro')
    // this.router.navigate(["Registro"]);
    this.navCtrl.navigateForward('/Registro', { animated: false })
    localStorage.setItem('reload', '1')
    this.statusRegistro()
  }

  goMain() {
    this.router.navigateByUrl('/main')
    this.statuLogin()
  }

  back() {
    window.history.back()
  }

  Servicio() {
    console.log(
      'La resolución de tu pantalla es: ' + screen.width + ' x ' + screen.height
    )
    if (localStorage.getItem('Servicio') === 'maid') {
      this.url = 'assets/imgs/Maid Login.png'
    } else {
      this.url = 'assets/imgs/Master Login.svg'
    }
  }

  statuLogin() {
    localStorage.setItem('Status', 'Login')
  }
  statusRegistro() {
    localStorage.setItem('Status', 'Registro')
  }

  guardarSession(event) {
    if (event.target.checked == true) {
      this.guardar = true
      return this.guardar
    }
  }

  setLogin() {
    localStorage.setItem('registrado', 'true')
    let sqlFind: string = `SELECT * FROM usuarios WHERE contrasena = SHA2(MD5(UNHEX(SHA2('${this.loginRender.inputContrasena.value}',512))),224) AND email = '${this.loginRender.inputEmail.value}'`
    this.sqlGenericService
      .excecuteQueryStringReference(sqlFind, `gettabla`)
      .subscribe(
        (rsp: any) => {
          console.log()

          if (rsp.parameters.length == 1) {
            localStorage.setItem(
              'password',
              JSON.stringify(this.loginRender.inputContrasena.value)
            )
            localStorage.setItem(
              'user',
              JSON.stringify(this.loginRender.inputEmail.value)
            )

            if (rsp.parameters[0].tipo_usuario == 3) {
              if (this.guardar == true) {
                localStorage.setItem(
                  'userSession',
                  JSON.stringify(rsp.parameters[0])
                )
              }

              localStorage.setItem('id_Usuario', rsp.parameters[0].id)
              localStorage.setItem('Servicio', 'admin')
              this.router.navigateByUrl('/ConfigCrm')
            }
            if (rsp.parameters[0].tipo_usuario == 2) {
              if (this.guardar == true) {
                localStorage.setItem(
                  'userSession',
                  JSON.stringify(rsp.parameters[0])
                )
              }
              localStorage.setItem('id_Usuario', rsp.parameters[0].id)
              localStorage.setItem('Servicio', 'master')
              this.router.navigateByUrl('/main')
            }
            if (rsp.parameters[0].tipo_usuario == 1) {
              if (this.guardar == true) {
                localStorage.setItem(
                  'userSession',
                  JSON.stringify(rsp.parameters[0])
                )
              }
              localStorage.setItem('id_Usuario', rsp.parameters[0].id)
              localStorage.setItem('Servicio', 'maid')
              this.router.navigateByUrl('/main')
            }
          } else {
            this.alertService.errorAlert(
              'Opps....',
              'Tenemos un Problema con el Usuario'
            )
          }
        },

        (error: HttpErrorResponse) => {
          if (error.status != 200) {
            this.alertService.errorAlert('Opps....', 'Tenemos un Problema')
          }
        }
      )
  }

  change() {
    //
    let id: any = document.getElementById('pass') as HTMLInputElement
    let Span: any = document.getElementById('iconpass') as HTMLInputElement
    console.log(Span.innerText)
    if (this.visto == true) {
      id.type = 'text'
      Span.innerText = 'visibility'
      this.visto = false
      console.log(this.visto)
    } else {
      id.type = 'password'
      Span.innerText = 'visibility_off'
      this.visto = true
      console.log(this.visto)
    }
  }
  async loginIos() {
    if (Capacitor.getPlatform() === 'web') {
      const appleProvider = new firebase.auth.OAuthProvider('apple.com')
      const res = await this.afAuth.signInWithPopup(appleProvider)
      const user = res.user

      this.data.email.value = (user as any)._delegate.email
      this.data.pass.value = (user as any)._delegate.accessToken
      this.data.repass.value = (user as any)._delegate.accessToken
      this.buscarUsuarioAuth(this.data.email.value)
    } else {
      let options: SignInWithAppleOptions = {
        clientId: 'mx.com.muchachapp',
        redirectURI: 'https://muchachapp-3aac9.firebaseapp.com/__/auth/handler',
        scopes: 'email name',
        state: '12345',
      }

      SignInWithApple.authorize(options)
        .then(async (result: SignInWithAppleResponse) => {
          const appleProvider = new firebase.auth.OAuthProvider('apple.com')
          const credential = appleProvider.credential({
            idToken: result.response.identityToken,
          })

          const userCredential = await this.afAuth.signInWithCredential(
            credential
          )

          const user = userCredential.user

          this.data.email.value = (user as any)._delegate.email
          this.data.pass.value = (user as any)._delegate.accessToken
          this.data.repass.value = (user as any)._delegate.accessToken

          this.buscarUsuarioAuth(this.data.email.value)
        })
        .catch((error) => {
          // Handle error
        })
    }
  }

  async loginFacebookAndroid() {
    if (Capacitor.getPlatform() === 'web') {
      const FaceBookProvider = new firebase.auth.FacebookAuthProvider()
      try {
        const res = await this.afAuth.signInWithPopup(FaceBookProvider)

        const user = res.user

        this.data.email.value = (user as any)._delegate.email
        this.data.pass.value = (user as any)._delegate.accessToken
        this.data.repass.value = (user as any)._delegate.accessToken

        this.buscarUsuarioAuth(this.data.email.value)
      } catch (error) {
        console.log(error)
        this.alertService.errorAlert(
          'Opps....',
          'El Usuario se registro con un Provedor Diferente se modifico Intente nuevamente'
        )
        this.afAuth.currentUser.then((user) => user?.delete())
      }
    } else {
      try {
        const res: FacebookLoginResponse = await this.fb.login([
          'public_profile',
          'email',
        ])

        const facebookCredential =
          firebase.auth.FacebookAuthProvider.credential(
            res.authResponse.accessToken
          )

        const resConfirmed = await this.afAuth.signInWithCredential(
          facebookCredential
        )

        const user = resConfirmed.user

        this.data.email.value = (user as any)._delegate.email
        this.data.pass.value = (user as any)._delegate.accessToken
        this.data.repass.value = (user as any)._delegate.accessToken

        this.buscarUsuarioAuth(this.data.email.value)
      } catch (error) {
        console.log(error)
        this.alertService.errorAlert(
          'Opps....',
          'El Usuario se registro con un Provedor Diferente se modifico Intente nuevamente'
        )
        this.afAuth.currentUser.then((user) => user?.delete())
      }
    }
  }

  async loginGoogleAndroid() {
    if (Capacitor.getPlatform() === 'web') {
      const res = await this.afAuth.signInWithPopup(
        new firebase.auth.GoogleAuthProvider()
      )
      const user = res.user

      this.data.email.value = (user as any)._delegate.email
      this.data.pass.value = (user as any)._delegate.accessToken
      this.data.repass.value = (user as any)._delegate.accessToken
      this.buscarUsuarioAuth(this.data.email.value)
    } else {
      const res = await this.googlePlus.login({
        webClientId:
          '1030105610609-h541c1i2j3fpap7ntpbhr9pf20h6d2f7.apps.googleusercontent.com',
        offline: true,
      })
      const resConfirmed = await this.afAuth.signInWithCredential(
        firebase.auth.GoogleAuthProvider.credential(res.idToken)
      )
      const user = resConfirmed.user

      this.data.email.value = (user as any)._delegate.email
      this.data.pass.value = (user as any)._delegate.accessToken
      this.data.repass.value = (user as any)._delegate.accessToken
      this.buscarUsuarioAuth(this.data.email.value)
    }
  }

  buscarUsuarioAuth(email) {
    console.log(email)
    let sqlFind: string = `SELECT * FROM usuarios WHERE email = '${email}'`
    this.sqlGenericService
      .excecuteQueryStringReference(sqlFind, `getusuario`)
      .subscribe((rsp: any) => {
        console.log(rsp)
        if (rsp.parameters.length == 1) {
          localStorage.setItem(
            'password',
            JSON.stringify(this.loginRender.inputContrasena.value)
          )
          if (rsp.parameters[0].tipo_usuario == 3) {
            localStorage.setItem(
              'userSession',
              JSON.stringify(rsp.parameters[0])
            )
            localStorage.setItem('id_Usuario', rsp.parameters[0].id)
            localStorage.setItem('Servicio', 'admin')
            this.router.navigateByUrl('/ConfigCrm')
          }
          if (rsp.parameters[0].tipo_usuario == 2) {
            localStorage.setItem(
              'userSession',
              JSON.stringify(rsp.parameters[0])
            )
            localStorage.setItem('id_Usuario', rsp.parameters[0].id)
            localStorage.setItem('Servicio', 'master')
            this.router.navigateByUrl('/main')
          }
          if (rsp.parameters[0].tipo_usuario == 1) {
            localStorage.setItem(
              'userSession',
              JSON.stringify(rsp.parameters[0])
            )
            localStorage.setItem('id_Usuario', rsp.parameters[0].id)
            localStorage.setItem('Servicio', 'maid')
            this.router.navigateByUrl('/main')
          }
        } else {
          this.alertService.errorAlert(
            'Opps....',
            'Tenemos un Problema con el Usuario'
          )
        }
      })
  }

  goForgotPassowrd() {
    this.router.navigateByUrl('/forgot-password')
  }
}
